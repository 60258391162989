// ##################################################
// Button
// ##################################################

@import '../../../scss/import';

// Style
.btn {
	cursor: pointer;
	padding: 8px 30px;
	min-height: 60px;
	background-color: var(--color-sky-blue);
	color: var(--color-white);
	border-radius: 10px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: 0;
	font-size: 50px;
	line-height: 0.84;
	letter-spacing: 4.25px;
	min-width: 198px;
	transition: background-color 0.25s ease-in, box-shadow 0.25s ease-in, color 0.3s ease;
	text-transform: uppercase;
	font-family: $font_secondary;
	text-align: center;
	background-image: linear-gradient(122deg, #00b3f1 12%, #2ab886 93%);

	// Hover/Focus
	&:hover,
	&:focus {
		box-shadow: 0 0 0 3px rgba($cgp_sky_blue, 0.3);
	}

	// ##################################################

	// Button text
	.btn__text {
		display: block;
		position: relative;
		z-index: 3;
		pointer-events: none;
	}
}
