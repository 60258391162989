//**********************************************************
// INPUT FIELD
//**********************************************************

@import '../../../scss/import';

.input-field {
	width: 100%;
	position: relative;
	text-align: left;
	@include placeholder($cgp_blue);
	font-size: 18px;
	line-height: 1.3;

	// ##################################################

	&__inner {
		position: relative;
	}

	// ##################################################

	&__label {
		margin-bottom: 4px;
		font-weight: 700;
	}

	// ##################################################

	&__input {
		width: 100%;
		height: 36px;
		border: 1px solid transparent;
		background-color: rgba(#50b8f5, 0.6);
		padding: 0 12px;
		border-radius: 3px;
		color: var(--color-blue);
		font-size: 18px;
		font-weight: 500;
		font-family: inherit;
		transition: border 0.3s ease, color 0.3s ease;

		&:focus {
			border-color: var(--color-blue);
		}
	}

	// ##################################################

	textarea {
		resize: none;
	}

	// ##################################################

	&.is-disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	// stylelint-disable-next-line
	&.#{$class_validation_failed} {
		// stylelint-disable-next-line
		.input-field__input {
			color: var(--color-red);
			border-color: var(--color-red);
		}
	}
}
