// ##################################################
// BACKGROUNDS
// ##################################################

.cover-bg {
	@extend %abs_pos_fullwidth;
	background-size: cover;
	background-position: center center;

	img {
		display: none;
	}
}

.covered {
	@extend %abs_pos_fullwidth;
	max-height: initial;
}

// ##################################################

.bg {
	&-image {
		img {
			@extend %abs_pos_fullwidth;
			max-height: initial;
		}
	}
}

// ##################################################
// TEXT ALIGN
// ##################################################

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

// ##################################################
// SCREEN READER
// ##################################################

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.button {
	@extend %reset_button;
}

// ##################################################
// TEXT
// ##################################################

.text {
	// ##################################################

	&--112 {
		@extend %fontsize112;
	}

	// ##################################################

	&--20 {
		@extend %fontsize20;
	}

	// ##################################################

	&--18 {
		@extend %fontsize18;
	}

	// ##################################################

	&--15 {
		@extend %fontsize15;
	}

	// ##################################################

	&--14 {
		@extend %fontsize14;
	}

	// ##################################################

	&--bold {
		font-weight: 700;
	}

	// ##################################################

	&--md {
		font-weight: 700;
	}

	// ##################################################

	&--uppercase {
		text-transform: uppercase;
	}
}

// ##################################################
// VISIBILITY
// ##################################################

.hide {
	display: none;
}

.show {
	display: block;
}

.d-flex {
	display: flex;
}

.d-flex-cc,
.d-flex-center-center {
	@extend %flex_center_center;
}

.container {
	max-width: 1200px;
	padding: 0 15px;
	width: 100%;
	display: block;
	margin: 0 auto;

	// ##################################################

	.container {
		max-width: 100%;
	}
}

.f-auto {
	display: flex;
	flex-direction: column;
	flex: auto;
}

.mx-auto {
	display: block;
	margin: 0 auto;
	width: 100%;
}
