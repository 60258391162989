// ##################################################
// 0.0 Icons
// ##################################################

@import '../../../scss/import';

// General Icons
.icon {
	// background: transparent no-repeat scroll center / contain;
	display: block;
	position: relative;

	// Icons for hover effect
	&::before {
		background-color: var(--color-black);
		-webkit-mask-size: contain;
		mask-size: contain;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		-webkit-mask-position: center;
		mask-position: center;
		@extend %content;
		transition: background-color 0.3s ease;
	}

	// ##################################################

	&-chevron-down,
	&-chevron-up {
		width: 9px;
		height: 6px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$chevron_down}');
			mask-image: url('data:image/svg+xml,#{$chevron_down}');
		}
	}

	// ##################################################

	&-chevron-left,
	&-chevron-right {
		width: 28px;
		height: 50px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$chevron_right}');
			mask-image: url('data:image/svg+xml,#{$chevron_right}');
		}
	}

	// ##################################################

	&-cross {
		width: 18px;
		height: 18px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_close}');
			mask-image: url('data:image/svg+xml,#{$icon_close}');
		}
	}

	// ##################################################

	&-chevron-left,
	&-chevron-up {
		&:before {
			transform: scale(-1);
		}
	}
}
