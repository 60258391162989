// Full width element
@use 'sass:math';

@mixin absoluted-fullwidth {
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

// Placeholder mixin
@mixin placeholder($color, $opacity: 1) {
	::-webkit-input-placeholder {
		color: $color;
		opacity: $opacity;
	}

	::-moz-placeholder {
		color: $color;
		opacity: $opacity;
	}

	::-ms-input-placeholder {
		color: $color;
		opacity: $opacity;
	}

	:-moz-placeholder {
		color: $color;
		opacity: $opacity;
	}

	:-ms-input-placeholder {
		color: $color;
		opacity: $opacity;
	}

	::placeholder {
		color: $color;
		opacity: $opacity;
	}
}

// Custom scrollbar
@mixin scrollbar($width, $foreground-color, $background-color, $radius) {
	scrollbar-track-color: $background-color;
	scrollbar-face-color: $foreground-color;
	scrollbar-arrow-color: $foreground-color;

	/* width */
	&::-webkit-scrollbar {
		width: $width;
	}

	/* Track */
	::-webkit-scrollbar-track {
		-webkit-border-radius: $radius;
		border-radius: $radius;
		background: $background-color;
	}

	::-webkit-scrollbar-thumb:window-inactive {
		background: mix($foreground-color, $background-color, 50%);
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: $foreground-color;
		width: $width;
		border-radius: $radius;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: mix($foreground-color, $background-color, 80%);
	}

	::-webkit-scrollbar {
		width: $width;
	}
}

// Selection mixin
@mixin selection($bg-color, $color) {
	::-webkit-selection {
		background-color: $bg-color;
		color: $color;
	}

	::-moz-selection {
		background-color: $bg-color;
		color: $color;
	}

	:-ms-selection {
		background-color: $bg-color;
		color: $color;
	}

	::selection {
		background-color: $bg-color;
		color: $color;
	}
}

@mixin user-select() {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

// generic transform
@mixin transition($transition...) {
	//-moz-transition: $transition;
	//-o-transition: $transition;
	//-ms-transition: $transition;
	transition: $transition;
	-webkit-transition: $transition;
}

// generic transform
@mixin transform($transforms...) {
	//-moz-transform: $transforms;
	//-o-transform: $transforms;
	//-ms-transform: $transforms;
	transform: $transforms;
	-webkit-transform: $transforms;
}

// rotate
@mixin rotate($deg) {
	@include transform(rotate($deg));
}

// scale
@mixin scale($scale...) {
	@include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
	@include transform(translate($x, $y));
}

// translate
@mixin translate3d($x, $y, $z) {
	@include transform(translate3d($x, $y, $z));
}

// skew
@mixin skew($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin($originx, $originy) {
	//moz-transform-origin: $originx, $originy;
	//-o-transform-origin: $originx, $originy;
	//-ms-transform-origin: $originx, $originy;
	transform-origin: $originx $originy;
	-webkit-transform-origin: $originx $originy;
}

//Border radius
@mixin border-radius($radius) {
	//-moz-border-radius: $radius;
	//-ms-border-radius: $radius;
	//-o-border-radius: $radius;
	border-radius: $radius;
	-webkit-border-radius: $radius;
}

@mixin box-shadow($input...) {
	//-moz-box-shadow: $input;
	//-ms-box-shadow: $input;
	//-o-box-shadow: $input;
	box-shadow: $input;
	-webkit-box-shadow: $input;
}

@mixin columns($num, $gap) {
	-moz-column-count: $num;
	-webkit-column-count: $num;
	column-count: $num;
	-moz-column-gap: $gap;
	-webkit-column-gap: $gap;
	column-gap: $gap;
}

// --------------------------------------------------
// Flexbox SASS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------

// Flexbox display
@mixin flexbox() {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin inline-flexbox() {
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
	-webkit-box-flex: $values;
	-moz-box-flex: $values;
	-webkit-flex: $values;
	-ms-flex: $values;
	flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
	-webkit-flex-direction: $direction;
	-moz-flex-direction: $direction;
	-ms-flex-direction: $direction;
	flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
	-webkit-flex-wrap: $wrap;
	-moz-flex-wrap: $wrap;
	-ms-flex-wrap: $wrap;
	flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
	-webkit-flex-flow: $flow;
	-moz-flex-flow: $flow;
	-ms-flex-flow: $flow;
	flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
	-webkit-box-ordinal-group: $val;
	-moz-box-ordinal-group: $val;
	-ms-flex-order: $val;
	-webkit-order: $val;
	order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
	-webkit-flex-grow: $grow;
	-moz-flex-grow: $grow;
	-ms-flex-grow: $grow;
	flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($shrink) {
	-webkit-flex-shrink: $shrink;
	-moz-flex-shrink: $shrink;
	-ms-flex-shrink: $shrink;
	flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($width) {
	-webkit-flex-basis: $width;
	-moz-flex-basis: $width;
	-ms-flex-basis: $width;
	flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
	-webkit-justify-content: $justify;
	-moz-justify-content: $justify;
	-ms-justify-content: $justify;
	justify-content: $justify;
	-ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
	-webkit-align-content: $align;
	-moz-align-content: $align;
	-ms-align-content: $align;
	align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
	-webkit-align-items: $align;
	-moz-align-items: $align;
	-ms-align-items: $align;
	align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align) {
	-webkit-align-self: $align;
	-moz-align-self: $align;
	-ms-align-self: $align;
	align-self: $align;
}

// Alignments
@mixin vertical-align($position: relative) {
	position: $position;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
}

@mixin horizontal-align() {
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
}

@mixin horizontal-vertical-align($position: relative) {
	position: $position;
	top: 50%;
	left: 50%;
	@include translate(-50%, -50%);
}

// Filters
@mixin filter-default($value) {
	-webkit-filter: $value;
	filter: $value;
}

@mixin filter-grayscale($percent: 100%) {
	-webkit-filter: grayscale($percent);
	filter: grayscale($percent);
}

//********************************************
// X.X - BREAKPOINT MIXINS
//********************************************

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin bp-up($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);

	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin bp-down($name, $breakpoints: $grid-breakpoints) {
	$max: breakpoint-max($name, $breakpoints);

	@if $max {
		@media (max-width: $max) {
			@content;
		}
	} @else {
		@content;
	}
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin bp-between($lower, $upper, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($lower, $breakpoints);
	$max: breakpoint-max($upper, $breakpoints);

	@if $min !=null and $max !=null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max==null {
		@include media-breakpoint-up($lower, $breakpoints) {
			@content;
		}
	} @else if $min==null {
		@include media-breakpoint-down($upper, $breakpoints) {
			@content;
		}
	}
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin bp-only($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	$max: breakpoint-max($name, $breakpoints);

	@if $min !=null and $max !=null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max==null {
		@include media-breakpoint-up($name, $breakpoints) {
			@content;
		}
	} @else if $min==null {
		@include media-breakpoint-down($name, $breakpoints) {
			@content;
		}
	}
}

//********************************************
// X.X - Animations and Keyframes
//********************************************

// Prefixer
@mixin prefixr($attr, $value, $prefixes) {
	@each $prefix in $prefixes {
		@if $prefix==webkit {
			-webkit-#{$attr}: $value;
			-moz-#{$attr}: $value;
		} @else if $prefix==cstm {
			#{$attr}: $value;
		}
	}
}

// Animations
@mixin anim-cstm($name, $coords: 0 0 0) {
	$duration: nth($coords, 1);
	$delay: nth($coords, 2);
	$iteration: nth($coords, 3);
	@include prefixr(animation-name, $name, webkit cstm);
	@include prefixr(animation-duration, $duration, webkit cstm);
	@include prefixr(animation-delay, $delay, webkit cstm);

	@if type-of($iteration) ==number and unitless($iteration) or type-of($iteration) !=number {
		@include prefixr(animation-iteration-count, $iteration, webkit cstm);
	} @else if type-of($iteration) ==number and not unitless($iteration) {
		@include prefixr(animation-iteration-count, 1, webkit cstm);
	}
}

// Keyframes
// use always with anim-cstm() @mixin
// name value need to be the same as anim-cstm() @mixin name value
@mixin anim-kf($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}

	//@-moz-keyframes #{$animation-name} { @content; }
	//@-ms-keyframes #{$animation-name} { @content; }
	//@-o-keyframes #{$animation-name} { @content; }
	@keyframes #{$animation-name} {
		@content;
	}
}

//********************************************
// X.X - Custom row and custom col ready
//********************************************/

// Custom col ready
@mixin make-custom-row-ready() {
	// Desktop XL
	@include bp-only(xl) {
		margin-left: -#{$gutter_xl * 0.5};
		margin-right: -#{$gutter_xl * 0.5};
	}

	// Desktop
	@include bp-only(lg) {
		margin-left: -#{$gutter_lg * 0.5};
		margin-right: -#{$gutter_lg * 0.5};
	}

	// Tablet L
	@include bp-only(md) {
		margin-left: -#{$gutter_md * 0.5};
		margin-right: -#{$gutter_md * 0.5};
	}

	// Tablet P
	@include bp-only(sm) {
		margin-left: -#{$gutter_sm * 0.5};
		margin-right: -#{$gutter_sm * 0.5};
	}

	// Mobile
	@include bp-only(xs) {
		margin-left: -#{$gutter_xs * 0.5};
		margin-right: -#{$gutter_xs * 0.5};
	}
}

// Custom col ready
@mixin make-custom-col-ready() {
	// Desktop XL
	@include bp-only(xl) {
		padding-left: $gutter_xl * 0.5;
		padding-right: $gutter_xl * 0.5;
	}

	// Desktop
	@include bp-only(lg) {
		padding-left: $gutter_lg * 0.5;
		padding-right: $gutter_lg * 0.5;
	}

	// Tablet L
	@include bp-only(md) {
		padding-left: $gutter_md * 0.5;
		padding-right: $gutter_md * 0.5;
	}

	// Tablet P
	@include bp-only(sm) {
		padding-left: $gutter_sm * 0.5;
		padding-right: $gutter_sm * 0.5;
	}

	// Mobile
	@include bp-only(xs) {
		padding-left: $gutter_xs * 0.5;
		padding-right: $gutter_xs * 0.5;
	}
}

// Calculate col width
@mixin calc-col($num) {
	$cont_cell: math.div($container_xl, 12) - ($gutter_xl - math.div($gutter_xl, 11));

	width: floor(($cont_cell * $num) + (($num - 1) * $gutter_xl));
}

//********************************************
// X.X - Remove hashtag from color code
//********************************************/

@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// Returns color withouth the hashtag in front
@function color-without-hash($color) {
	$stringed_color: quote('#{$color}');
	$sliced_string: str-replace($stringed_color, '#', '');
	@return $sliced_string;
}
