// **************************************************
// Section Header
// **************************************************

@import '../../../scss/import';

.section-header {
	position: relative;
	width: 100%;

	// ##################################################

	&__bg {
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		height: 350px;
		margin-top: -170px;
		overflow: hidden;

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 30vh;
			margin-top: -15vh;
		}

		// ##################################################

		&-inner {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			background-size: auto 100%;
			background-repeat: repeat-x;
			background-position: 10% center;
		}
	}

	// ##################################################

	.title {
		margin-bottom: 2vh;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 0;
		}
	}

	// ##################################################

	.container {
		position: relative;
		z-index: 3;
	}

	// ##################################################

	img {
		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 28vh;
		}
	}
}
