// **************************************************
// Site Header
// **************************************************

@import '../../../scss/import';

.site-header {
	position: absolute;
	top: 34px;
	z-index: 100;
	right: 46px;

	// ##################################################

	&__logo {
		display: inline-block;
		max-width: 172px;
		width: 100%;

		// ##################################################

		img {
			width: 100%;
			height: auto;
			object-fit: contain;
		}
	}
}
