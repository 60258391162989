//**********************************************************
// Personal Info
//**********************************************************

@import '../../../scss/import';

.personal-info {
	position: fixed;
	z-index: 200;
	width: 100%;
	height: 100%;
	overflow: auto;
	top: 0;
	left: 0;
	padding: 50px 0 20px;

	// ##################################################

	&__background {
		position: fixed;

		// ##################################################

		&::before {
			@extend %content;
			opacity: 0.5;
			z-index: 2;
			background-color: var(--color-white);
		}
	}

	// ##################################################

	.container {
		// Tablet P and up
		@media #{$breakpoint-sm-up} {
			max-width: 680px;
		}
	}

	// ##################################################

	&__super-title {
		margin-bottom: 10px;
	}

	// ##################################################

	&__title {
		margin-bottom: 24px;
	}

	// ##################################################

	&__header {
		position: relative;
		text-transform: uppercase;
		z-index: 3;
		margin-bottom: 22px;
	}

	// ##################################################

	&__text {
		font-weight: 300;

		strong {
			font-weight: 700;
		}
	}

	// ##################################################

	&__form {
		position: relative;
		z-index: 3;
		margin: 0 auto;
		display: block;
		max-width: 568px;

		// ##################################################

		&-title {
			margin-bottom: 20px;
		}

		// ##################################################

		&-mandatory-text {
			font-size: 12px;
			font-weight: 100;
			padding: 0 12px;
			margin-bottom: -12px;
		}

		// ##################################################

		&-info-text {
			font-size: 16px;
			font-weight: 100;
			padding: 0 12px;
			line-height: 1;
		}

		// ##################################################

		&-inner {
			display: grid;
			grid-gap: 14px 40px;
			grid-template-columns: repeat(2, minmax(0, 1fr));

			// Mobile
			@media #{$breakpoint-xs-only} {
				grid-gap: 12px;
				grid-template-columns: 1fr;
			}
		}

		// ##################################################

		&-item {
			&.span-2 {
				grid-column: span 2;
			}
		}

		// ##################################################

		&-submit {
			text-align: center;
			margin-top: 14px;
		}
	}

	// ##################################################

	.checkbox {
		font-size: 12px;
		margin-top: 6px;

		// ##################################################

		&__label {
			padding-left: 42px;
			line-height: 1.13;
			padding: 0 0 0 28px;
		}
	}
}
