//**********************************************************
// Screen Saver
//**********************************************************

@import '../../../scss/import';

.screen-saver {
	position: relative;
	width: 100%;
	height: 100vh;
	z-index: 200;
}
