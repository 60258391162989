// **************************************************
// Checkbox
// **************************************************

@import '../../../scss/import';

.checkbox {
	display: flex;
	align-items: center;
	user-select: none;

	// Mobile
	@media #{$breakpoint-xs-only} {
		min-height: 1px;
	}

	// ##################################################

	& + .checkbox {
		margin-top: 24px;
	}

	// ##################################################

	&__label {
		display: block;
		position: relative;
		padding-left: 30px;
		line-height: normal;
		cursor: pointer;
		margin: 0;
		font-weight: 500;
		transition: color 0.3s ease;

		// ##################################################

		&::before,
		&::after {
			content: '';
			position: absolute;
			border-radius: 100%;
		}

		// ##################################################

		// Unchecked circle
		&::before {
			width: 22px;
			height: 22px;
			left: 0;
			top: -4px;
			background-color: var(--color-white);
			transition: border 0.3s ease;
		}

		// ##################################################

		// Checked circle
		&::after {
			left: 5px;
			top: 1px;
			width: 12px;
			height: 12px;
			background-color: var(--color-blue);
			transition: transform 0.3s ease;
			transform: scale(0);
		}
	}

	// ##################################################

	a {
		border: 0;
		text-decoration: underline;
	}

	// ##################################################

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		// ##################################################

		&:checked + .checkbox__label {
			&::after {
				@include scale(1);
			}
		}

		// ##################################################

		&:focus-visible + .checkbox__label {
			&::before {
				border-color: var(--color-sky-blue);
			}
		}
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_validation_failed} {
		color: var(--color-red);
	}
}
