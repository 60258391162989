//*******************************************************************************
// PLACEHOLDERS
//*******************************************************************************

// General extensions
%content {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	pointer-events: none;
}

// ##################################################

%reset_button {
	background: transparent;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	padding: 0;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	color: inherit;
}

// ##################################################

%abs_pos_fullwidth {
	height: 100%;
	left: 0;
	position: absolute;
	width: 100%;
	top: 0;
	pointer-events: none;
}

// ##################################################

%abs_pos_fullwidth_normal {
	height: 100%;
	left: 0;
	position: absolute;
	width: 100%;
	top: 0;
}

// ##################################################

%flex_center_center {
	display: flex;
	align-items: center;
	justify-content: center;
}

// ##################################################

%sr_only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	white-space: nowrap;
	border: 0;
	visibility: inherit;
	clip: rect(0, 0, 0, 0);
}

// ##################################################
// FONT SIZES
// ##################################################

// General page text - Paragraph
%page_text {
	font-size: 18px;
	line-height: 1.5;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 16px;
	}
}

// ##################################################

%title {
	font-weight: 700;
	line-height: 1.2;
	text-transform: uppercase;
}

// ##################################################

%title_h1 {
	@extend %title;
	font-size: 50px;
	line-height: 1.4;
}

// ##################################################

%title_h2 {
	@extend %title;
	font-size: 40px;
	letter-spacing: 0.6px;
}

// ##################################################

%title_h3 {
	@extend %title;
	font-size: 30px;
	letter-spacing: 0.45px;
	line-height: 1;
}

// ##################################################

%title_h4 {
	@extend %title;
	font-size: 24px;
	line-height: 1.5;
}

// ##################################################

%title_h5 {
	@extend %title;
	font-size: 20px;
	line-height: 1.5;
}

// ##################################################

%title_h6 {
	@extend %title;
	font-size: 15px;
	line-height: 1.5;
}

// ##################################################

%fontsize112 {
	font-family: $font_secondary;
	text-shadow: 2px 2px 0 var(--color-blue);
	font-size: 112px;
	line-height: 0.59;
	letter-spacing: 1.68px;
	color: var(--color-green);
}

// ##################################################

%fontsize20 {
	font-size: 20px;
	line-height: 1.8;
}

// ##################################################

%fontsize18 {
	font-size: 18px;
	line-height: 1.5;
}

// ##################################################

%fontsize15 {
	font-size: 15px;
	line-height: 1.3;
}

// ##################################################

%fontsize14 {
	font-size: 14px;
	line-height: 1.42;
}

// ##################################################

%fontsize13 {
	font-size: 13px;
	line-height: normal;
}

// ##################################################

%fontsize12 {
	font-size: 12px;
	line-height: 1.42;
}

// ##################################################
// ##################################################

%inherit_textstyles {
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	text-transform: inherit;
	text-align: inherit;
	font-style: inherit;
	font-family: inherit;
}

// ##################################################
// Margins
// ##################################################

%margin-160 {
	margin-bottom: 160px;

	// Tablet
	@media #{$breakpoint-md-down} {
		margin-bottom: 120px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-bottom: 180px;
	}
}

%margin-120 {
	margin-bottom: 120px;

	// Tablet
	@media #{$breakpoint-md-down} {
		margin-bottom: 80px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-bottom: 60px;
	}
}

%margin-100 {
	margin-bottom: 100px;

	// Tablet
	@media #{$breakpoint-md-down} {
		margin-bottom: 80px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-bottom: 60px;
	}
}

%margin-80 {
	margin-bottom: 100px;

	// Tablet
	@media #{$breakpoint-md-down} {
		margin-bottom: 60px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-bottom: 40px;
	}
}

%margin-60 {
	margin-bottom: 60px;

	// Tablet
	@media #{$breakpoint-md-down} {
		margin-bottom: 40px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-bottom: 30px;
	}
}

%margin-40 {
	margin-bottom: 40px;

	// Tablet
	@media #{$breakpoint-md-down} {
		margin-bottom: 30px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-bottom: 20px;
	}
}

%margin-30 {
	margin-bottom: 30px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-bottom: 20px;
	}
}

// ##################################################

%hover_lines {
	// ##################################################
	// Bottom line

	// stylelint-disable-next-line
	&::before,
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		width: 0;
		right: 0;
		height: 1px;
		background-color: currentColor;
		pointer-events: none;
	}

	// ##################################################

	// stylelint-disable-next-line
	&::before {
		transition: width 0.4s cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
	}

	// ##################################################

	// stylelint-disable-next-line
	&::after {
		transition: width 0.2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
	}

	// stylelint-disable-next-line
	&.#{$class_active},
	&:hover {
		// stylelint-disable-next-line
		&::after,
		&::before {
			width: 100%;
			left: 0;
		}
	}
}
