//**********************************************************
// Intro screen
//**********************************************************

@import '../../../scss/import';

.stopwatch {
	position: relative;
	height: 100vh;
	overflow: hidden;
	width: 100%;
	padding-top: 160px;

	// ##################################################

	&__body {
		margin: 0 auto;
		width: 100%;
		max-width: 638px;
		height: 638px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}

	// ##################################################

	&__outer-circle {
		animation: spine 30s linear infinite forwards;
	}

	// ##################################################

	&__inner-circle {
		top: -10px;
	}

	// ##################################################

	&__main {
		position: relative;
		z-index: 3;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 24px;
		margin-top: 38px;

		// ##################################################

		&-display {
			display: flex;
			align-items: flex-end;
			font-family: $font_secondary;
			font-size: 124px;
			line-height: 0.6;
			letter-spacing: 1.86px;
		}

		// ##################################################

		&-separator {
			margin: 0 -14px;
		}

		// ##################################################

		&-milliseconds {
			font-size: 60px;
			letter-spacing: 0.9px;
			line-height: 0.7;
			position: relative;
			left: 10px;
		}
	}
}

@keyframes spine {
	0% {
		transform: rotate(0deg) translate(0, 0);
	}
	13% {
		transform: rotate(45deg) translate(-16px, 5px);
	}
	25% {
		transform: rotate(90deg) translate(-24px, 20px);
	}
	37% {
		transform: rotate(135deg) translate(-16px, 36px);
	}
	50% {
		transform: rotate(180deg) translate(-2px, 40px);
	}
	63% {
		transform: rotate(225deg) translate(10px, 36px);
	}
	75% {
		transform: rotate(270deg) translate(20px, 23px);
	}
	87% {
		transform: rotate(315deg) translate(14px, 8px);
	}
	100% {
		transform: rotate(360deg) translate(0, 0);
	}
}
