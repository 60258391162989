//**********************************************************
// Game Module
//**********************************************************

@import '../../../scss/import';

.game-module {
	position: relative;
	width: 100%;

	// ##################################################

	&__step {
		display: none;

		&.is-visible {
			display: block;
		}
	}
}
