// **************************************************
// Footer
// **************************************************

@import '../../../scss/import';

.site-footer {
	position: absolute;
	left: 16px;
	z-index: 100;
	top: 24px;

	// ##################################################

	&__nav {
		// ##################################################

		ul {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		// ##################################################

		li {
			display: inline-flex;
			margin: 0 14px;

			// Smaller screen
			@media (max-width: 400px) {
				margin: 0 6px;
			}
		}

		// ##################################################

		a {
			display: inline-block;
			position: relative;
			font-size: 14px;
			font-weight: 700;
			line-height: 1.5;
			letter-spacing: 0.21px;
			text-transform: uppercase;
			color: var(--color-blue);
			@extend %hover_lines;

			&::before,
			&::after {
				height: 2px;
				background-color: currentColor;
			}
		}
	}
}
